.requests-form-cf {
  .ui.accordion {
    .active.title {
      display: none;
    }
    .ui.container {
      margin-left: 0 !important;
      margin-right: 0 !important;
      width: auto;
    }
  }
}
