.ui.modal {
  &.form-modal.community-invitations {
    .invenio-text-area-field {
      margin-bottom: 0 !important;
      .field {
        margin-bottom: 1rem !important;
      }
    }
  }
}
