.ui.button {
  &.fluid-responsive {
    @media all and (max-width: @largestTabletScreen) {
      width: 100%;
      min-width: 7em;
      display: block;
    }
  }
  &.fluid-mobile {
    @media all and (max-width: @largestMobileScreen) {
      width: 100%;
      min-width: 7em;
      display: block;
    }
  }
}
