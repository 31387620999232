.ui.modal {
  &.requests-request-modal {
    .field {
      margin-bottom: 1rem !important;
    }
    .ui.accordion {
      .active.title {
        display: none;
      }
      .ui.container {
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: auto;
      }
    }
  }
}

.ui.modal .actions > .button {
  margin-bottom: 0.5rem;
}
// weird issue when opening modal inside of the modal, if the main modal is long enough, the other modal (confirmation)
// opens on top of the page instead of the center
.ui.page.modals.dimmer.transition.visible.active {
  .requests.dangerous-action-confirmation-modal {
    top: 35%;
  }
}
