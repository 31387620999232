.ui.button {
  &.request-create-button {
    display: block;
    margin-bottom: 10px;

    &.delete_published_record {
      background-color: @red;
      color: @white;
    }
    &.publish_draft {
      color: white;
      background-color: @green;
    }
  }
  &.request-reply-button {
    display: block;
    margin-bottom: 10px;
    &.publish_draft {
      color: white;
      background-color: @green;
    }
    &.delete_published_record {
      background-color: @red;
      color: @white;
    }
  }
}

#request-detail {
  .ui.button {
    &.requests.request-accept-button,
    &.requests.requests-cancel-button,
    &.requests.request-decline-button,
    &.requests.request-cancel-button {
      float: right;
      margin-bottom: 0.5rem;
    }
  }
}
